import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {MainService} from '../core/main.service';
import {AppService} from '../services/app.service';
import {DefaultAddress, UserProfile} from '../entity/profile.entity';
import {Comment} from '../entity/comment.entity';
import {Address} from '../entity/address.entity';
import {HistoryService} from './history.service';
import {SurveyService} from './survey.service';
import {CatalogService} from './catalog.service';
import {CartService} from './cart.service';
import {CartDeliver, OrderStep} from '../entity/cart.entity';
import {ApiResponse} from '../entity/apiResponse.entity';
import {CommentService} from './comment.service';
import {AddressService} from './address.service';
import {PizzaService} from './pizza.service';
import {WokService} from './wok.service';
import {DeliveryType} from '../entity/lists';
import {Deposit} from '../entity/deposit.entity';
import {PopupDepositComponent} from '../components/popups/popup-deposit/popup-deposit.component';
import {BehaviorSubject} from 'rxjs';
import {NavigationService} from '../services/navigation.service';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService extends MainService {
    get defaultAddress(): DefaultAddress {
        if (!this.privateDefaultAddress) {
            const city = this.appService.getCityId() || '0';
            try {
                this.privateDefaultAddress = JSON.parse(localStorage.getItem('com.ionicframework.hatimaki__localDefaultAddress_' + city));
            } catch (e) {
                this.privateDefaultAddress = null;
            }
        }
        return this.privateDefaultAddress;
    }

    set defaultAddress(value: DefaultAddress) {
        const city = this.appService.getCityId() || '0';
        localStorage.setItem('com.ionicframework.hatimaki__localDefaultAddress_' + city, JSON.stringify(value));
        this.privateDefaultAddress = value;
    }

    private privateDefaultAddress: DefaultAddress = null;


    get user(): UserProfile {
        return this.privateUser;
    }

    set user(value: UserProfile) {
        // обновляем данные о пользователе в чате verbox
        this.updateVerboxUser(value);

        if (this.isLogin() && this.user?.defaultAddress) {
            // Если были авторизованы ранее - сохраняем из профиля адрес в localStorage
            if (value.defaultAddress) {
                this.defaultAddress = value.defaultAddress;
            }
        } else if (value.id !== '0') {
            // Если не были авторизованы ранее - сохраняем деф адрес из апы в профиль
            if (!this.defaultAddress) {
                this.defaultAddress = value.defaultAddress;
            }
            this.saveDefaultAddress(this.defaultAddress, () => {
            }, {loadCatalog: false});
        }
        this.privateUser = value;
        this.userSubject.next(value?.id.length && value?.id.toString() !== '0');
    }

    private privateUser: UserProfile;
    userSubject = new BehaviorSubject(false);
    registrData: any;
    token: string | null;
    comments: Comment[] = [];
    addresses: Address[] = [];

    historyService: HistoryService;
    surveyService: SurveyService;
    catalogService: CatalogService;
    cartService: CartService;
    commentService: CommentService;
    addressService: AddressService;

    pizzaService: PizzaService;
    wokService: WokService;

    smsData: any;

    isHasDefaultAddress = false;
    deposit: Deposit = null;

    phoneAttemptsNumber = 0;
    smsAttemptsNumber = 0;

    constructor(
        protected http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService,
        historyService: HistoryService,
        surveyService: SurveyService,
        cartService: CartService,
        commentService: CommentService,
        addressService: AddressService,
        catalogService: CatalogService,
        pizzaService: PizzaService,
        wokService: WokService,
        private navigationService: NavigationService,
    ) {
        super(http, alert, loader, appService);

        this.historyService = historyService;
        this.surveyService = surveyService;
        this.catalogService = catalogService;
        this.cartService = cartService;
        this.commentService = commentService;
        this.addressService = addressService;

        this.pizzaService = pizzaService;
        this.wokService = wokService;

        this.user = new UserProfile();

        if (!environment.production) {
            (this.appService.getWindow() as any).userService = this;
        }
    }

    private urlRequest = 'reg_json2.php';
    public phone = '';

    makePostRequest(data, callback) {
        if (data.hasOwnProperty('login')) {
            this.phone = data.login;
        }
        this.postRequest(data, callback, this.urlRequest);
    }

    resetPhone() {
        this.phone = '';
    }

    resetAuthType() {
        if (this.catalogService.settings?.authorization) {
            this.phoneAttemptsNumber = this.catalogService.settings.authorization.phoneAttemptsNumber;
            this.smsAttemptsNumber = this.catalogService.settings.authorization.smsAttemptsNumber;
        } else {
            this.phoneAttemptsNumber = 0;
            this.smsAttemptsNumber = 0;
        }
    }

    getAuthType() {
        return this.phoneAttemptsNumber > 0 ? 'phone' : 'sms';
    }

    checkValidDefaultAddress(user) {
        if (
            user.defaultAddress?.courier?.kladrId &&
            user.defaultAddress?.courier?.x &&
            user.defaultAddress?.courier?.y
        ) {
            // console.log('есть kladrId, x и y');
            // если есть kladrId, x и y, то localStorage defaultAddress перезаписываетя данными из бека
            this.defaultAddress = user.defaultAddress;
        } else {
            // console.log('нет kladrId, x или y');
            // если нет kladrId, x и y, то defaultAddress удаляется из localStorage
            this.clearLocalDefaultAddress();
            // и обнуляются значения this.defaultAddress.courier, что вынудит пользователя при попытке
            // заказа доставки снова ввести адрес, и тогда у него уже запишется kladrId, x и y
            this.defaultAddress.courier.street = '';
            this.defaultAddress.courier.kladrId = '';
            this.defaultAddress.courier.value = '';
            this.defaultAddress.courier.x = '';
            this.defaultAddress.courier.y = '';
        }
    }

    // @todo переписать
    // обработка данных после логина, сохранение в модель и в локал сторейдж
    private processLogin(res, callback: (status: boolean, errorText: string, isCode: boolean) => any) {
        let status: boolean;
        let errorText = '';
        let isCode = false;

        if (res.id) {
            this.user = res;
            this.token = res.id;
            localStorage.setItem('com.ionicframework.hatimaki__token', res.id);

            if (res.city === null && res.city !== 'undefined' && typeof res.city !== 'undefined') {
                localStorage.setItem('com.ionicframework.hatimaki__city', res.city);
            }
            if (typeof res.city !== 'undefined') {
                this.cartService.sync(this.cartService.cart?.products?.length ? OrderStep.Sync : OrderStep.Start, () => {
                    this.cartService.updateQuantity$.emit(null);
                });
            }
            // очищаются временные значения
            this.appService.userPhone = '';
            this.appService.prevPhone = '';

            status = true;
        } else if (res.code) {
            this.registrData = res;
            status = false;
            errorText = '';
            isCode = true;
        } else if (res.success) {
            status = true;
            errorText = res.error;
            isCode = false;
        } else {
            status = false;
            errorText = res.error;
            isCode = false;
        }

        callback(status, errorText, isCode);
    }

    // получение базовых данных юзера
    public getUser(callback: (status: boolean) => any, force = true) {
        // this.token = localStorage.getItem('com.ionicframework.hatimaki__token');
        const data = {
            action: 'getProfile'
        };

        if (force || this.user.id.toString() === '0') {
            this.postRequest(data, (res: UserProfile) => {
                if (res.id) {
                    this.user = res;
                    // сразу проверяем депозит
                    this.getDeposit(() => {});
                    // если пользователь в чёрном списке, то сразу логаут
                    // и далее он просто не может авторизоваться
                    if (!this.user.active) {
                        this.logout(() => {
                            callback(false);
                        });
                        return;
                    }
                    // проверка на валидность адреса только если тип доставки курьер
                    if (this.user.defaultAddress?.type === 1) {
                        this.checkValidDefaultAddress(this.user);
                    }
                    this.isHasDefaultAddress = this.isHasDefaultAddress || !!res.defaultAddress;
                }

                callback(true);
            }, this.urlRequest);
        } else {
            callback(true);
        }

    }

    // основной метод логина
    public login(data: any,
                 callback: (
                     status: boolean,
                     errorText: string,
                     isCode: boolean
                 ) => any,
                 message: boolean = true,
                 loading: boolean = true
    ) {
        let errorText: string;

        if (data.hasOwnProperty('login')) {
            errorText = this.validationPhone(data.login);

            if (errorText) {
                callback(false, errorText, false);
                return;
            }

            this.phone = data.login;
        }

        this.postRequest(data, (res) => {
            this.processLogin(res, callback);
        }, this.urlRequest, message, loading);

    }

    passwordRecovery(data, callback: (response: ApiResponse) => any) {
        this.regJsonRequest<ApiResponse>(data, (status, answer) => {
            callback(answer);
        }, false, true);
    }

    checkPhone(data, callback: (response: ApiResponse) => any) {

        data.action = 'checkPhone';

        this.regJsonRequest<ApiResponse>(data, (status, answer) => {
            callback(answer);
        }, false, true);
    }

    validationPhone(phone) {
        let errorText = '';
        if (!phone || phone.length !== 10) {
            errorText = 'Телефон указан некорректно';
        }

        return errorText;
    }

    // Отправка токена устройства на сервер, оставим на вырост
    public addDevice(device: string, type: string) {

    }

    // Удаление токена устройства с серверп, оставим на вырост
    public removeDevice(device: string) {

    }

    // Логин при входе в апу, у нас это получение профиля
    makeLogin(callback: (status: boolean) => any) {

        this.token = this.appService.getUserToken();

        if (this.token !== 'null' && this.token !== 'undefined' && this.token) {

            this.getUser(res => {
                if (res) {
                    callback(res);
                } else {
                    this.logout(() => {
                        callback(false);
                    });
                }

            });

        } else {
            callback(false);
        }
    }

    async sendRecoveryRequest(data) {
        if (data) {
            data.action = 'recovery2';
        }
        this.passwordRecovery(data, (response) => {
            if (response.result) {
                this.navigationService.goToPage('auth/code');
            } else {
                this.appService.showMessage(response.message);
            }
        });
    }

    public saveNotification(notificationValue, callback: (status: boolean) => void, loading = false) {
        const data = {
            action: 'saveNotification',
            profile: notificationValue
        };

        this.regJsonRequest(data, status => {
                if (status) {
                    this.getUser(callback);

                }
            }, false, loading
        );
    }

    public updateProfile(value, callback: (status: boolean) => void, loading = false) {
        const data = {
            action: 'saveProfile',
            profile: {...this.user, ...value}
        };

        this.regJsonRequest(data, status => {
                if (status) {
                    this.getUser(callback);
                }
            }, false, loading
        );
    }

    public getDeposit(callback?: (answer: ApiResponse<Deposit>) => void, loading = false, callbackError?) {
        this.getRequest({id: this.user.id}, (answer: ApiResponse<Deposit>) => {
            this.deposit = answer?.result || null;
            if (this.deposit?.isShowPopup) {
                this.appService.openModal(PopupDepositComponent, {deposit: this.deposit});
            }
            if (callback) {
                callback(answer);
            }
        }, this.getUrl('user/deposit', true), false, loading, callbackError);
    }

    public postDeleteProfile(callback?: (answer) => void, loading = false) {
        this.appService.showLoading();
        this.appService.closeModal();
        this.postRequest({userId: Number(this.user.userId)}, (answer) => {
            if (answer.status) {
                // выход из аккаунта
                this.logout(data => {
                    if (data) {
                        // очищаются данные из LocalStorage
                        this.clearLocalStorage();
                        // очищается токен
                        this.token = null;
                        // очищаются логин и телефон
                        this.smsData = {};
                        // очищается адрес
                        this.defaultAddress = null;
                        // очищаются временные значения
                        this.appService.userPhone = '';
                        this.appService.prevPhone = '';
                        // переходе в меню в котором сразу открывается попап с выбором адреса, defaultAddress null
                        this.navigationService.goToPage('menu', true);
                    }
                });
            }
            this.appService.hideLoading();
        }, this.getUrl('user/delete', true), false, loading);
    }

    // Проверка если юзер залогинен по айди в обьекте профиля
    public isLogin() {
        return this.user?.id?.toString() !== '0';
    }

    logout(callback: (status: boolean) => void) {

        this.appService.unSubscribePush(this.user.userId);

        /*
        * @TODO добавить остальное обнуление данных
        * */

        localStorage.removeItem('com.ionicframework.hatimaki__token');
        this.user = new UserProfile();
        this.isHasDefaultAddress = false;
        this.surveyService.ordersWithSurveyCount = '0';
        this.catalogService.clearSelectedSideDish();
        // очищается токен
        this.token = null;
        // очищаются временные значения
        this.appService.userPhone = '';
        this.appService.prevPhone = '';
        // очищается депозит
        this.deposit = null;

        this.historyService.clean();
        this.historyService.cleanActiveOrders();
        this.commentService.clean();
        this.addressService.clean();

        this.pizzaService.clear();
        this.wokService.clear();

        this.cartService.newCart();
        this.cartService.updateQuantity$.emit(null);

        callback(true);

        if (this.catalogService.getHelpText('birthday', 'text')) {
            this.catalogService.getCatalog(status => {

            }, null, true);
        }

    }

    clearLocalDefaultAddress() {
        localStorage.removeItem('com.ionicframework.hatimaki__defaultAddress_null');
        localStorage.removeItem('com.ionicframework.hatimaki__localDefaultAddress_0');
        localStorage.removeItem('com.ionicframework.hatimaki__localDefaultAddress_1');
    }


    clearLocalStorage() {
        localStorage.removeItem('com.ionicframework.hatimaki__city');
        localStorage.removeItem('com.ionicframework.hatimaki__defaultAddress_null');
        localStorage.removeItem('com.ionicframework.hatimaki__localDefaultAddress_0');
        localStorage.removeItem('com.ionicframework.hatimaki__localDefaultAddress_1');
        localStorage.removeItem('com.ionicframework.hatimaki__menuType'); // для сброса вида меню
    }

    saveDefaultAddress(
        address: DefaultAddress,
        callback?: (type: 'address' | 'catalog') => void,
        options: {
            loading?: boolean,
            message?: boolean,
            loadCatalog?: boolean
        } = {}
    ) {
        const defaultOptions = {
            loading: true,
            message: false,
            loadCatalog: true
        };
        options = {...defaultOptions, ...options};
        if (this.isLogin()) {
            this.postRequest({defaultAddress: address}, (res) => {
                if (res?.status && address) {
                    this.user.defaultAddress = address;
                    this.defaultAddress = address;
                    this.isHasDefaultAddress = true;

                    if (!this.cartService.cart.delivery) {
                        this.cartService.cart.delivery = new CartDeliver();
                    }
                    switch (address.type) {
                        case DeliveryType.Pickup:
                            this.cartService.cart.delivery.pickup = address.pickup;
                            break;
                        case DeliveryType.Courier:
                            this.cartService.cart.delivery.courier = address.courier;
                            if (address.courierPoint) {
                                this.cartService.cart.delivery.courierPoint = address.courierPoint;
                            }
                            break;
                    }
                    this.cartService.cart.delivery.type = address.type;

                    callback?.('address');
                    if (options.loadCatalog) {
                        this.catalogService.getCatalog(() => {
                            this.appService.changeMenu();
                            callback?.('catalog');
                            this.catalogService.showBranchPopup();
                        }, this.token, true);
                    }
                }
            }, this.getUrl('user/defaultAddress', true), options.message, options.loading);
        } else {
            this.defaultAddress = address;
            callback?.('address');
            if (options.loadCatalog) {
                this.catalogService.getCatalog(() => {
                    this.appService.changeMenu();
                    callback?.('catalog');
                    this.catalogService.showBranchPopup();
                }, this.token, true);
            }
        }
    }

    getDefaultAddress() {
        const city = this.appService.getCityId() || '0';
        try {
            this.privateDefaultAddress = JSON.parse(localStorage.getItem('com.ionicframework.hatimaki__localDefaultAddress_' + city));
        } catch (e) {
            this.privateDefaultAddress = null;
        }
        return this.privateDefaultAddress;
    }

    updateVerboxUser(value: UserProfile) {
        if (value?.userId !== '0' && this.user?.userId !== value.userId) {
            const info = {
                name: value.name || '',
                phone: value.phone || '',
                email: value.email || '',
            };
            this.appService.getWindow().VerboxSetup = {clientId: value.userId};
            this.appService.getWindow().Verbox('reload', () => {
                this.appService.getWindow().Verbox('setClientInfo', info);
            });
        }
    }

    clearUserPhone() {
        this.appService.userPhone = '';
    }
}
