import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { CatalogService } from '../models/catalog.service';
import { UserService } from '../models/user.service';
import {BehaviorSubject} from 'rxjs';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {Geoposition} from '@ionic-native/geolocation';

declare let cordova: any;

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    appService: AppService;
    catalogService: CatalogService;
    userService: UserService;
    geolocation: Geolocation;
    goToUserPosition = false;
    private updateMapSubject = new BehaviorSubject<boolean>(false);
    private goToUserPositionSubject = new BehaviorSubject<boolean>(false);

    // Экспонируем как Observable для подписки
    goToUserPosition$ = this.goToUserPositionSubject.asObservable();

    setGoToUserPosition(value: boolean) {
        this.goToUserPositionSubject.next(value);
    }

    constructor(
        appService: AppService,
        catalogService: CatalogService,
        userService: UserService,
        geolocation: Geolocation,
    ) {
        this.appService = appService;
        this.catalogService = catalogService;
        this.userService = userService;
        this.geolocation = geolocation;
    }

    // Метод для установки флага обновления карты
    setUpdateMapFlag(value: boolean) {
        this.updateMapSubject.next(value);
    }

    // Observable для подписки на изменения флага
    getUpdateMapFlag() {
        return this.updateMapSubject.asObservable();
    }

    async requestGeolocationPermission(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.appService.isAndroid()) {
                // Логика для Android
                cordova.plugins.diagnostic.requestLocationAuthorization((status) => {
                    switch (status) {
                        case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
                            console.log('Permission not requested. Requesting now...');
                            // Попробуем запросить разрешение еще раз
                            cordova.plugins.diagnostic.requestLocationAuthorization((newStatus) => {
                                if (newStatus === cordova.plugins.diagnostic.permissionStatus.GRANTED ||
                                    newStatus === cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
                                    console.log('Permission granted after request');
                                    this.watchPosition();
                                    resolve();
                                } else {
                                    console.log('Permission denied after request');
                                    resolve(); // Разрешение отклонено, продолжаем выполнение
                                }
                            }, (error) => {
                                console.error('Error during second request for location authorization', error);
                                resolve(); // Даже при ошибке продолжаем выполнение
                            });
                            break;

                        case cordova.plugins.diagnostic.permissionStatus.GRANTED:
                        case cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
                            console.log('Permission granted');
                            this.watchPosition();
                            resolve(); // Разрешение получено, продолжаем
                            break;

                        case cordova.plugins.diagnostic.permissionStatus.DENIED:
                        case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
                            console.log('Permission denied');
                            resolve(); // Разрешение отклонено, продолжаем выполнение
                            break;

                        default:
                            console.error('Unknown permission status');
                            resolve(); // Продолжаем выполнение при неизвестном статусе
                            break;
                    }
                }, (error) => {
                    console.error('Android: Error requesting location authorization', error);
                    resolve(); // Продолжаем выполнение даже в случае ошибки
                });
            } else if (this.appService.isIos()) {
                // Логика для iOS
                cordova.plugins.diagnostic.getLocationAuthorizationStatus(async (status) => {
                    switch (status) {
                        case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
                            cordova.plugins.diagnostic.requestLocationAuthorization(async (newStatus) => {
                                if (newStatus === cordova.plugins.diagnostic.permissionStatus.GRANTED ||
                                    newStatus === cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
                                    console.log('iOS: Permission granted after request');
                                    await this.watchPosition();
                                    resolve();
                                } else {
                                    console.log('iOS: Permission denied after request');
                                    resolve(); // Разрешение отклонено, продолжаем выполнение
                                }
                            }, (error) => {
                                console.error('iOS: Error during second request for location authorization', error);
                                resolve(); // Даже при ошибке продолжаем выполнение
                            });
                            break;

                        case cordova.plugins.diagnostic.permissionStatus.GRANTED:
                        case cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
                            await this.watchPosition();
                            resolve(); // Разрешение получено, продолжаем
                            break;

                        case cordova.plugins.diagnostic.permissionStatus.DENIED:
                        case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
                            console.log('iOS: Permission denied');
                            resolve(); // Разрешение отклонено, продолжаем выполнение
                            break;

                        default:
                            console.error('iOS: Unknown permission status');
                            resolve(); // Продолжаем выполнение при неизвестном статусе
                            break;
                    }
                }, (error) => {
                    console.error('iOS: Error getting location authorization status', error);
                    resolve(); // Продолжаем выполнение даже в случае ошибки
                });
            } else {
                console.error('Unsupported platform');
                resolve(); // В случае неподдерживаемой платформы продолжаем выполнение
            }
        });
    }

    async watchPosition() {
        const watch = this.geolocation.watchPosition({ enableHighAccuracy: true, timeout: 60000 });

        watch.subscribe((position: Geoposition) => {
            if (position && position.coords) {
                this.appService.coords = [position.coords.latitude, position.coords.longitude];
                // console.log('Updated coordinates:', this.appService.coords);  // Лог для отслеживания обновления координат
            } else {
                console.error('Failed to get coordinates');
            }
        }, (error) => {
            console.error('Error in watchPosition:', error); // Лог ошибки
        });
    }

    async getAddressByCoords(coords: [number, number]): Promise<any> {
        if (!coords) {
            console.error('Coordinates are not provided');
            return null;
        }

        const ymapsLoaded = await this.loadYandexMap();

        await window['ymaps'].ready(() => {});

        if (!ymapsLoaded || !window['ymaps'] || !window['ymaps'].geocode) {
            console.error('Yandex Maps was not loaded correctly or is undefined');
            return null;
        }

        return window['ymaps'].geocode(coords, { kind: 'house', precision: 'exact' }).then((res) => {
            const geoObject = res.geoObjects.get(0);
            if (!geoObject) {
                return { address: '', house: '', isNotOnHouse: true };
            }

            const house = geoObject.getPremiseNumber();
            const geometry = geoObject.geometry;
            const coordinates = geometry.getCoordinates();

            let isPointOnHouse = false;

            if (geometry.getType() === 'Polygon') {
                isPointOnHouse = geometry.contains(coords);
            } else {
                const distance = window['ymaps'].coordSystem.geo.getDistance(coords, coordinates);
                const maxDistanceToHouse = 50; // Можно увеличить значение для длинных домов
                isPointOnHouse = distance <= maxDistanceToHouse;
            }

            if (house && isPointOnHouse) {
                const addressComponents = geoObject.getAddressLine().split(', ');
                addressComponents.shift();
                const address = addressComponents.join(', ').replace('улица', '').replace(' ,', ',');
                return { address, house: this.parseYandexHouse(house), isNotOnHouse: false };
            } else {
                const addressComponents = geoObject.getThoroughfare()?.split(', ');
                const address = addressComponents?.join(', ')?.replace('улица', '').replace(' ,', ',');
                return { address, house: '', isNotOnHouse: true };
            }
        });
    }

    loadYandexMap() {
        return new Promise((resolve, reject) => {
            if (!this.appService.ymapsLoaded) {
                const script: HTMLScriptElement = document.createElement('script');
                script.type = 'text/javascript';
                script.src = 'https://api-maps.yandex.ru/2.1/?apikey=' + this.catalogService.ymaps_apikey + '&lang=ru_RU';

                script.onload = () => {
                    this.appService.ymapsLoaded = true;
                    resolve(true);
                };

                script.onerror = (error: any) => resolve(false);
                document.querySelector('head').append(script);
            } else {
                resolve(true);
            }
        });
    }

    parseYandexHouse(house): any {

        // 25А
        // 25с1
        // 25к3
        // 25Ас1
        // 25к1с1
        // 25к1с1
        // вл25к1с1
        // вл25/5к1с1

        const regex1 = /^[^\d]*(\d+\/\d+|\d+)(.*)/;
        const regex2 = /^к/;

        const res = house.match(regex1);

        return {
            house: res[1],
            building: res[2].replace(regex2, '')
        };

    }

    async getCoordinatesFromYandex(addressUri: string) {
        const url = `https://geocode-maps.yandex.ru/1.x/?apikey=${this.catalogService.ymaps_apikey}&uri=${addressUri}&format=json`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.response && data.response.GeoObjectCollection.featureMember.length > 0) {
                const geoObject = data.response.GeoObjectCollection.featureMember[0].GeoObject;
                const coords = geoObject.Point.pos.split(' ').map(Number);
                return {
                    x: coords[1], // Долгота
                    y: coords[0], // Широта
                };
            }
            throw new Error('Coordinates not found');
        } catch (error) {
            console.error('Error fetching coordinates:', error);
            return null;
        }
    }

}
