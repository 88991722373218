import {
    Component,
    AfterViewInit,
    Input,
    Output,
    EventEmitter,
    ViewChildren,
    QueryList,
    ElementRef,
    ChangeDetectorRef,
    OnChanges, SimpleChanges
} from '@angular/core';
import { NavigationService } from '../../services/navigation.service';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterViewInit, OnChanges  {
    navigationService: NavigationService;
    @Input() items = [];
    @Input() initialActiveTab: string;
    @Input() deliveryZoneTitle = false;
    @Input() cannotGoBack = false;
    @Output() activeTab = new EventEmitter<any>();

    @ViewChildren('tabElement', { read: ElementRef }) tabElements: QueryList<ElementRef>;

    selectedTab: string;
    @Input() noShadow = false;
    position = 0;
    width = 0;

    constructor(
        navigationService: NavigationService,
        private cdr: ChangeDetectorRef
    ) {
        this.navigationService = navigationService;
    }

    ngAfterViewInit() {
        // Устанавливаем начальный активный таб
        if (this.initialActiveTab) {
            this.setInitialActiveTab();
        } else {
            this.selectedTab = this.items[0].title;
            this.updateBackground(0);
        }

        // Откладываем выполнение updateBackground, чтобы дать Angular время на рендеринг
        setTimeout(() => {
            this.updateBackground(this.items.findIndex(item => item.title === this.selectedTab));
        }, 0);

        // Принудительно обновляем представление
        this.cdr.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.initialActiveTab && !changes.initialActiveTab.firstChange) {
            this.setInitialActiveTab();
        }
    }

    selectTab(tab, index) {
        this.selectedTab = tab.title;
        this.updateBackground(index);
        this.activeTab.emit(tab);
    }

    private updateBackground(index: number) {
        const tabElement = this.tabElements.toArray()[index].nativeElement;

        // Обновляем позицию и ширину фона
        this.position = tabElement.offsetLeft;
        this.width = tabElement.offsetWidth;
    }

    private setInitialActiveTab() {
        const index = this.items.findIndex(item => item.title === this.initialActiveTab);
        if (index !== -1) {
            this.selectedTab = this.initialActiveTab;
            this.updateBackground(index);
        } else {
            console.warn('Initial active tab not found');
        }
    }
}
