import {Component, OnInit} from '@angular/core';
import {CatalogService} from '../../../models/catalog.service';
import {AppService} from '../../../services/app.service';

@Component({
    selector: 'app-popup-souses-list',
    templateUrl: './popup-souses-list.component.html',
    styleUrls: ['./popup-souses-list.component.scss']
})
export class PopupSousesListComponent implements OnInit {
    text: any = 'text from server';
    textIsOpen = false;
    catalogService: CatalogService;
    products: any[] = [];
    appService: AppService;

    constructor(
        catalogService: CatalogService,
        appService: AppService
    ) {
        this.catalogService = catalogService;
        this.appService = appService;
    }

    ngOnInit() {
        this.products = this.catalogService.findByIdProperty('isSauce', true);
    }

    close() {
        this.appService.closeModal();
    }

    goToDelivery() {
        this.appService.closeModal({action: 'goToDelivery'});
    }
}
