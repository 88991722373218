import {Injectable} from '@angular/core';
import {MainService} from '../core/main.service';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {AppService} from '../services/app.service';
import {Order} from '../entity/order.entity';
import {OrderReviewStatuses, OrderStatuses} from '../entity/lists';
// import {filter} from 'rxjs/operators';
import {ApiResponse} from '../entity/apiResponse.entity';
import {CartService} from './cart.service';
import {CatalogService} from './catalog.service';
import {Product} from '../entity/product.entity';
import {DeliveryType} from '../entity/cart.entity';

@Injectable({
    providedIn: 'root',
})

export class HistoryService extends MainService {

    private cartService: CartService;
    private catalogService: CatalogService;

    public ordersCount = 0;
    public data: Order[] = [];
    public ordersWithSurvey: Order[] = [];
    public completedSurvey: Order[] = [];
    public notCompletedSurvey: Order[] = [];
    public activeOrders: Order[] = [];

    public limit = 15;
    private lastSyncTime: Date = new Date();
    private syncTimer = null;

    // HATIMAKI-1153 теперь все это приходит с бэка - this.catalogService.statuses[statusSlug]
    // public orderStatusList = {
    //     0: {name: 'in-process', title: 'Проверка'},
    //     1: {name: 'in-process', title: 'Готовится'},
    //     2: {name: 'in-process', title: 'Собирается'},
    //     3: {name: 'in-process', title: 'Доставляется'},
    //     4: {name: 'completed', title: 'Выполнен'},
    //     5: {name: 'canceled', title: 'Отменен'},
    //     6: {name: 'canceled', title: 'Ожидание'},
    // };

    constructor(
        http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        cartService: CartService,
        catalogService: CatalogService,
        appService: AppService
    ) {
        super(http, alert, loader, appService);
        this.cartService = cartService;
        this.catalogService = catalogService;
    }


    public getHistoryStatus(recordStatus: string) {
        const result = {
            title: this.catalogService.statuses[recordStatus].title,
            color: this.catalogService.statuses[recordStatus].color,
            icon: this.catalogService.statuses[recordStatus].icon,
        };
        return result;
    }

    // Обновление истории заказов на странице истори заказов
    public getData(page = 1, firstCall = true, callback?: (status: boolean) => void, loading = false) {
        this.postRequest({
            limit: this.limit,
            page,
        }, (response: ApiResponse) => {
            // Обновляем данные для страницы истории заказов
            this.data = firstCall ? response.result.historyOrders : this.data = [...this.data, ...response.result.historyOrders];
            this.ordersCount = response.result.count;
            this.getOrdersWithSurvey();

            // Запускаем таймер для обновления шапки заказов
            this.setSyncTimer();
            // Обновляем список активных закаов для шапки
            this.filterActiveOrders(this.data);
            this.appService.cleanCdr();
            callback?.(true);
        }, 'history.v2.php', false, loading);
    }

    /** Метод зупускающий обновление шапки активных закаов через таймер
     *
     * @param force - если true - обновление запустится через полный цикл времени (5 мин.),
     * если false - запустится через 5 минут - (<текущее_время> - <время_последнего_обновления>)
     */
    public setSyncTimer(force = true) {
        let delay = 300000;
        if (!force) {
            const now = new Date();
            delay = delay - (now.valueOf() - this.lastSyncTime.valueOf());
            delay = delay < 0 ? 0 : delay;
        }
        if (this.syncTimer) {
            clearTimeout(this.syncTimer);
        }
        this.syncTimer = setTimeout(() => {
            this.getData();
        }, delay);
    }

    public filterActiveOrders(data) {
        if (this.syncTimer) {
            this.activeOrders = data.filter(order => order.status < 4);
        }
        this.appService.cleanCdr();
    }

    clean() {
        this.data = [];
    }

    public cleanOrders() {
        this.data = this.data.slice(0, this.limit);
    }

    getOrdersWithSurvey() {
        this.ordersWithSurvey = this.data.filter(order => {
            if (order.status === OrderStatuses.Completed && order.reviewId) {
                return true;
            }
        });

        this.completedSurvey = this.ordersWithSurvey.filter(order => {
            if (order.reviewStatus === OrderReviewStatuses.Completed) {
                return true;
            }
        });

        this.notCompletedSurvey = this.ordersWithSurvey.filter(order => {
            switch (order.reviewStatus) {
                case OrderReviewStatuses.None:
                case OrderReviewStatuses.Writing:
                    return true;
            }
        });
    }

    public cancelOrder(callback: (status: boolean) => void) {

    }

    public updateOrder(callback: (status: boolean) => void) {

    }

    cleanActiveOrders() {
        if (this.syncTimer) {
            clearTimeout(this.syncTimer);
            this.syncTimer = null;
        }
        this.activeOrders = [];
    }

    genWokId(product: Product) {
        const id = [];
        product.options.forEach(item => {
            if (item && item.checked) {

                let q = '';

                if (item.quantity > 1) {
                    q = '-' + item.quantity;
                }

                id.push(item.id + q);
            }
        });
        return id.join('_');
    }

    repeatOrder(order: Order, callback: () => void) {
        if (order.cityId.toString() === this.appService.getCityId().toString()) {
            this.cartService.newCart();

            this.cartService.cart.delivery.type = order.deliveryType;
            this.cartService.cart.payment.type = order.paymentType;
            this.cartService.cart.data.persons = order.persons;
            this.cartService.cart.data.phone = order.phone;
            this.cartService.cart.data.comment.text = order.comment;

            if (order.cutlery) {
                this.cartService.cart.data.cutlery = order.cutlery;
            }

            if (order.deliveryType === DeliveryType.Pickup) {

                // console.log(order.branchId);
                // console.log(this.branchService.data);
                // console.log(this.branchService.findById(order.branchId));

                this.cartService.cart.delivery.pickup = order.branch;

            } else if (order.deliveryType === DeliveryType.Courier) {
                this.cartService.cart.delivery.courier.comment = order.deliveryComment;
                this.cartService.cart.delivery.courier.office = order.office;
                this.cartService.cart.delivery.courier.intercom = order.intercom;
                this.cartService.cart.delivery.courier.street = order.street;
                this.cartService.cart.delivery.courier.floor = order.floor;
                this.cartService.cart.delivery.courier.porch = order.porch;
            }


            for (const i in order.items) {
                if (order.items.hasOwnProperty(i)) {
                    const item = order.items[i];
                    if (item.isWok) {

                        item.dishId = this.genWokId(item);
                        this.cartService.addToCart(item, item.quantity, null, item.options);
                    } else if (item.isPizza) {

                        item.dishId = item.pizzaId;
                        item.catName = item.category;
                        this.cartService.addToCart(item, item.quantity, null, item.options);
                    } else {
                        const dish = this.catalogService.mainProductArray.find(d => d.id === item.dishId);

                        if (dish) {

                            // if(dish.offerId){
                            this.cartService.addToCart(dish, item.quantity, dish.offerId ? dish.offerId.toString() : null);
                            // } else {
                            //     this.cartService.addToCart(dish, item.quantity);
                            // }
                        }
                    }

                }
            }

            callback();
        }
    }
}
