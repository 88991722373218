import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {MainService} from '../core/main.service';
import {AppService} from '../services/app.service';
import {AddressSearchAnswer} from '../entity/addressSearchAPIAnswer.entity';
import {ApiResponse} from '../entity/apiResponse.entity';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DeliveryService extends MainService {

    hatimakitelegramUrl = 'https://m.hatimakitelegram.ru/index.php';
    apiUrl = environment.newApiUrl;

    constructor(
        protected http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService
    ) {
        super(http, alert, loader, appService);
    }

    getCityId() {
        const cityId = +localStorage.getItem('com.ionicframework.hatimaki__city');
        return cityId === 0 ? 1 : 2;
    }

    getCoordZonesS(callback) {
        const data = {
            r: 'maps/google/zones-json',
            site_id: 1,
            city_id: this.getCityId()
        };

        this.getRequest(data, callback, this.hatimakitelegramUrl);
    }

    getCoordZones(callback) {

        this.getRequest(
            {},
            (response: ApiResponse) => {
                callback(response.result);
            }, 'location/zones', true, true);
    }

    // checkCoords(coords, callback) {
    //     const data = {
    //         r: 'maps/google/check-coords-json',
    //         x: coords[0],
    //         y: coords[1],
    //         site_id: 1,
    //         city_id: this.getCityId()
    //     };
    //
    //     this.getRequest(data, callback, this.hatimakitelegramUrl);
    // }

    checkAddressOld(address, callback) {

        const data = {
            r: 'maps/google/check-address-json',
            address,
            site_id: 1,
            city_id: this.getCityId()
        };

        this.getRequest(data, callback, this.hatimakitelegramUrl);
    }

    checkAddress(data, strict = 1, callback: (status: boolean, result: AddressSearchAnswer) => void) {

        this.getRequest({
            term: data.term,
            strict,
            kladrId: data.kladrId,
            x: data.x,
            y: data.y
        }, (response: ApiResponse) => {
            callback(response.status, response.result);
        }, 'location/confirm', false, !data.notLoading);

    }

    checkCoords(x, y, callback: (status: boolean, result: AddressSearchAnswer) => void) {

        this.getRequest({
            x,
            y
        }, (response: ApiResponse) => {
            callback(response.status, response.result);
        }, 'location/confirmCoordinates', false, true);

    }
}
