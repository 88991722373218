import {Entity} from '../core/main.entity';

export class Branch implements Entity {
    id: number;
    close: string;
    active?: boolean;
    showInRestaurants: boolean;
    cookingTime: string;
    cookingTimeText?: string;
    coord_lat: string;
    coord_lon: string;
    advantages?: Advantage[];
    images: [];
    name: string;
    terms: string;
    address: string;
    guid: string;
    metro: string;
    open: string;
    phone: string;
    promoText: string;
    street: string;
    mobileCards: string;
    detailText: string;
    disabledReason: string;
    accountNumber: string;
    termsShort: string;
    timeOptions: {
        start: string,
        end: string,
        delay: string
    };
    slug: string;
    pickupDiscount?: string;
}
export class Advantage implements Entity {
    title: string;
    icon: string;
    main: boolean;
}
