import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ChangeDetectorRef,
    ChangeDetectionStrategy
} from '@angular/core';
import {MainItem} from '../../core/main.item';
import {CartService} from '../../models/cart.service';
import {ProductModalService} from 'src/app/services/product-modal.service';
import {CatalogService} from 'src/app/models/catalog.service';
import {ProductOffer} from 'src/app/entity/productOffer.entity';
import {AppService} from 'src/app/services/app.service';
import {Product} from 'src/app/entity/product.entity';
import {NavigationService} from 'src/app/services/navigation.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {PickerColumnOption} from '@ionic/core';
import {ProductModalComponent} from '../product-modal/product-modal';
import {ModalService} from '../../services/modal.service';
import {WokModalComponent} from '../wok-constructor-modal/wok-modal';
import {PizzaModalComponent} from '../pizza-constructor-modal/pizza-modal';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'product-item',
    styleUrls: ['./item.scss'],
    templateUrl: 'item.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProductItemComponent extends MainItem implements OnDestroy, OnInit {

    @Input() product: Product;
    @Input() enabledOpenModal = true;

    qty = 0;
    offers: ProductOffer[];
    offerId = 0;
    selectedOfferIndex: number;
    subscriptions: Subscription[] = [];
    sideDishes: ProductOffer[] = [];
    sideDishName: string;
    selectedSideDish: ProductOffer & { selectedIndex?: number };
    favButtonLocked = false;

    cartService: CartService;
    cdr: ChangeDetectorRef;
    catalogService: CatalogService;
    productModalService: ProductModalService;
    appService: AppService;
    navigationService: NavigationService;
    router: Router;
    modalService: ModalService;
    sanitization: DomSanitizer;

    constructor(
        cartService: CartService,
        cdr: ChangeDetectorRef,
        catalogService: CatalogService,
        productModalService: ProductModalService,
        appService: AppService,
        navigationService: NavigationService,
        router: Router,
        modalService: ModalService,
        sanitization: DomSanitizer
    ) {
        super();

        this.cartService = cartService;
        this.cdr = cdr;
        this.catalogService = catalogService;
        this.productModalService = productModalService;
        this.appService = appService;
        this.navigationService = navigationService;
        this.router = router;
        this.modalService = modalService;
        this.sanitization = sanitization;
    }

    ngOnInit() {

        // if (this.product.isWok) {
        //     console.log(this.product);
        // }

        this.getSideDishes();

        if (this.product.offers && this.product.offers.length) {
            this.offers = this.product.offers;
            if (this.product.offerSelectType === 'radio') {
                this.offerId = this.product.offerId = this.offers[this.offers.length - 1].id;
                this.getSelectedOfferIndex(this.offerId);
            }
        } else {
            this.offerId = this.product.offerId = null;
        }

        this.subscriptions.push(this.catalogService.updateStopList$.subscribe(() => {
            this.updateFavorite();
            this.cdr.detectChanges();
        }));
        this.subscriptions.push(this.catalogService.updateFavorite$.subscribe(() => {
            this.updateFavorite();
            this.cdr.detectChanges();
        }));
        this.subscriptions.push(this.cartService.updateQuantity$.subscribe(() => {
            this.qtyInCart();
        }));

        // подписаться на изменени количества товаров (в ProductModal)
        this.subscriptions.push(this.productModalService.updateQuantity$.subscribe(() => {
                this.qtyInCart();
                this.setActiveSideDish();
                this.cdr.detectChanges();
            }
        ));

        this.subscriptions.push(this.productModalService.selectOfferInModal$.subscribe(offerId => {
                if (this.product.offers) {
                    this.product.offers.forEach(offer => {
                        if (offer.id === offerId) {
                            this.offerId = offerId;
                        }
                    });
                }

                // this.offerId = offerId;

                this.setActiveSideDish();
                this.cdr.detectChanges();
            }
        ));

        this.qtyInCart();
    }

    getSideDishes() {
        if (this.product.offerSelectType === 'select') {
            this.sideDishes = this.product.offers;
            this.setActiveSideDish();
        }
    }

    setActiveSideDish() {
        if (this.sideDishes.length) {

            if (this.catalogService.selectedSideDishes[this.product.id]) {
                this.selectedSideDish = this.catalogService.selectedSideDishes[this.product.id];

                this.sideDishName = this.selectedSideDish.subName;
                this.setDishOfferId(this.selectedSideDish.id);
                this.cdr.detectChanges();
            } else {
                this.saveSideDishToCatalog();
            }
        }
    }

    saveSideDishToCatalog(selectedItem: ProductOffer & { selectedIndex?: number } = null) {
        let offerId: number;
        if (selectedItem && selectedItem.subName) {
            offerId = selectedItem.id;
            this.sideDishName = selectedItem.subName;
            this.catalogService.selectedSideDishes[this.product.id] = selectedItem;
            this.selectedSideDish = selectedItem;
        } else {
            offerId = this.product.offers[0].id;
            this.selectedSideDish = this.product.offers[0];
            this.sideDishName = this.product.offers[0].subName;
        }


        this.setDishOfferId(offerId);
        this.cdr.detectChanges();
    }

    getSelectedOfferIndex(offerId: number) {
        for (let i = 0; i <= this.product.offers.length - 1; i++) {
            if (this.product.offers[i].id === offerId) {
                this.selectedOfferIndex = i;
            }
        }
        // const activeOffer = this.product.offers.find(offer => offer.id === offerId);
        // this.selectedOfferIndex = this.product.offers.indexOf(activeOffer);
    }

    setDishOfferId(offerId: number, event: Event = null) {
        if (event) {
            event.stopPropagation();
        }

        this.offerId = offerId;
        this.product.offerId = offerId;
        this.qtyInCart();
        this.getSelectedOfferIndex(offerId);

        this.cdr.detectChanges();
    }

    // для iOS - значение селекта по умолчаию (Ion-picker)

    public openSideDishPicker(evt: Event) {
        evt.stopPropagation();
        const options = this.getColumnOptions();
        const selectedIndex = this.getActivePickerIndex();
        const classPicker = 'side-dish-picker';

        this.appService.openPicker(options, selectedIndex, classPicker, (selectedSideDish: ProductOffer & { selectedIndex: number }) => {
            this.product.offerId = selectedSideDish.id;
            this.setDishOfferId(selectedSideDish.id);
            this.saveSideDishToCatalog(selectedSideDish);
        });
    }

    getActivePickerIndex() {
        let index = 0;
        if (this.catalogService.selectedSideDishes[this.product.id]) {
            index = this.catalogService.selectedSideDishes[this.product.id].selectedIndex;
        }
        return index;
    }

    private getColumnOptions() {
        const options: PickerColumnOption[] = [];
        this.product.offers.forEach(offer => {
            options.push({
                text: offer.subName,
                value: offer
            });
        });

        return options;
    }

    offerName(offer) {
        if (offer.size) {
            return offer.size;
        } else if (offer.litrag) {
            return offer.litrag;
        } else if (offer.gramm) {
            return offer.gramm;
        }
        return '';
    }

    getType(offer): string {
        if (this.product.type === 'pie') {

            return offer.sizeType === 1 ? 'pie' : 'pies';
        } else if (this.product.type === 'basic') {
            return '';
        } else {
            return this.product.type;
        }
    }

    qtyInCart() {
        if (this.offerId) {
            this.qty = this.cartService.getQty(this.product.id, this.offerId);
        } else {
            this.qty = this.cartService.getQty(this.product.id);
        }

        this.cdr.detectChanges();
    }

    getPrice(typePrice: string) {

        const product: Product = new Product();
        Object.assign(product, this.product);

        return product.getPrice(1, typePrice);
    }

    addQty(e: Event) {
        if (this.isNoBuyTime) {
            return;
        }
        e.stopPropagation();
        this.qty = this.cartService.addToCart(this.product, +1, this.product.offerId);

        /*
        * @TODO добавить сюда оффер и вок
        * */
    }

    removeQty(e: Event) {
        if (this.isNoBuyTime) {
            return;
        }
        e.stopPropagation();
        this.qty = this.cartService.addToCart(this.product, -1, this.product.offerId);
    }

    openModal(product) {

        if (product.isWok || product.isPizza) {
            this.openConstructorModal(product);
        } else {
            if (this.enabledOpenModal) {
                this.productModalService.openProductModal(ProductModalComponent, this.product);
            }
        }

    }

    openConstructorModal(product) {

        if (product.isWok) {
            this.modalService.createModal(WokModalComponent);
        } else if (product.isPizza) {
            this.modalService.createModal(PizzaModalComponent);
        }

    }

    onChangeSelect(evt: Event) {
        evt.stopPropagation();
        this.catalogService.selectedSideDishes[this.product.id] = this.selectedSideDish;
        this.product.offerId = this.selectedSideDish.id;
        this.setDishOfferId(this.product.offerId);
    }

    onFavButtonClick(event: Event, product) {
        event.stopPropagation();

        if (!this.favButtonLocked) {
            this.favButtonLocked = true;

            this.catalogService.changeFavoriteState(product, (res) => {
                if (res.status) {
                    this.product.isFavorite = res.result;
                }
                this.favButtonLocked = false;
                this.cdr.detectChanges();

                this.catalogService.deleteHiddenItems(() => {
                    // this.cdr.detectChanges();
                });
            });
        }
    }

    updateFavorite() {
        const i = this.catalogService.products[this.product.categoryId]?.findIndex(item => item.id === this.product.id);
        this.product.isFavorite = this.catalogService.products[this.product.categoryId][i].isFavorite;
    }

    onClick(evt: Event) {
        evt.stopPropagation();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions.length = 0;
    }

    ionViewWillEnter() {
    }

    preventSwipe(status) {
        // console.log('empty preventSwipe');
    }

    get isNoBuyTime() {
        return !this.product.availability && this.product.availabilityText;
    }
}
