import {AfterViewInit, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MainComponent} from '../../core/main.component';
import {AppService, FirebaseTraces} from '../../services/app.service';
import {CatalogService} from '../../models/catalog.service';
import {HelperService} from '../../services/helper.service';
import {SwiperComponent, SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {UserService} from '../../models/user.service';
import {NavigationService} from '../../services/navigation.service';
import {PopupWorkTimeComponent} from '../../components/popups/popup-work-time/popup-work-time.component';
import {PopupBirthdayComponent} from '../../components/popups/popup-birthday/popup-birthday.component';
import {Subscription} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {AnalyticsService} from '../../services/analytics.service';
import * as smoothscroll from 'smoothscroll-polyfill';
import {ModalService} from '../../services/modal.service';
import {PromoModalComponent} from '../../components/promo-modal/promo-modal';
import {BannerModalComponent} from '../../components/banner-modal/banner-modal';
import {PopupSelectAddressComponent} from '../../components/popups/popup-select-address/popup-select-address';
import {DeliveryType} from '../../entity/cart.entity';
import {ActivatedRoute} from '@angular/router';
import {BranchService} from '../../models/branch.service';
import {DefaultAddress} from '../../entity/profile.entity';
import {AddressSearchAnswer} from '../../entity/addressSearchAPIAnswer.entity';
import {DeliveryService} from '../../models/delivery.service';

@Component({
    selector: 'app-menu',
    templateUrl: 'page.html',
    styleUrls: ['page.scss']
})
export class MenuPage extends MainComponent implements AfterViewInit, OnInit, OnDestroy {

    private slides: any[] = [];

    subscriptions: Subscription[] = [];

    navSliderInit = false;
    subNavSliderInit = false;
    menuNgSliderInit = false;
    blogSliderInit = false;

    @ViewChild('navSlider') navSlider: SwiperComponent;
    navSliderOptions: SwiperConfigInterface = {
        slidesPerView: 'auto',
        centeredSlides: true,
        centeredSlidesBounds: true,
        centerInsufficientSlides: true,
        observer: true,
        freeMode: true,
        freeModeMomentum: true,
        freeModeMomentumVelocityRatio: 1,
        freeModeMomentumRatio: 0.5
    };

    @ViewChild('subNavSlider') subNavSlider: SwiperComponent;
    subNavSliderOptions: SwiperConfigInterface = {
        slidesPerView: 'auto',
        centeredSlides: true,
        nested: true,
        observer: true,
        freeMode: true,
        freeModeMomentum: true,
        freeModeMomentumVelocityRatio: 1,
        freeModeMomentumRatio: 0.5,
    };

    @ViewChild('menuNgSlider') menuNgSlider: SwiperComponent;
    menuNgSliderOptions: SwiperConfigInterface = {
        cssMode: false,
        autoHeight: true,
        slidesPerView: 1,
        observer: true
    };

    blogSliderActiveIndex = 0;
    @ViewChild('blogSlider') blogSlider: SwiperComponent;
    blogSliderOptions: SwiperConfigInterface = {
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        nested: true,
        slidesPerView: 1,
        resistanceRatio: 0,
        initialSlide: -1,
        loop: true,
        observer: true,
        lazy: {
            loadPrevNext: true,
        },
    };


    selectedCat = 0;
    selectedCatForSub = 0;
    selectedCatHaveSubCategoryFlag = 0;
    subCategoryOffsets: any[] = [];
    selectedSubCategory = 0;
    showSubMenu = false;
    selectedSubCatElem: HTMLElement = null;

    scrollTimer: any;
    scrollTime = 300;
    scrollInAction = false;

    scrollMenuTimer: any;
    scrollMenuTime = 1000;
    scrollMenuInAction = false;

    menuTypeTimer: any;
    menuTypeTimerReady = true;

    catalogService: CatalogService;
    cdr: ChangeDetectorRef;
    zone: NgZone;
    sanitization: DomSanitizer;

    modalService: ModalService;
    activatedRoute: ActivatedRoute;
    branchService: BranchService;
    deliveryService: DeliveryService;

    timer: any;
    isClickedNav: boolean;
    swiperLock = true;
    phone: string;

    selectedSubCategoryIndex = {};

    DeliveryType = DeliveryType;

    isMenuLoading = false;
    pageInTop = true;
    private changeMenu$: Subscription;

    onIndexChangeBlogSlider(index: number) {
        this.blogSliderActiveIndex = index;
    }


    constructor(
        appService: AppService,
        navigationService: NavigationService,
        userService: UserService,
        catalogService: CatalogService,
        cdr: ChangeDetectorRef,
        zone: NgZone,
        sanitization: DomSanitizer,
        modalService: ModalService,
        activatedRoute: ActivatedRoute,
        branchService: BranchService,
        deliveryService: DeliveryService
    ) {
        super(appService, navigationService, userService);

        this.catalogService = catalogService;
        this.cdr = cdr;
        this.zone = zone;
        this.sanitization = sanitization;

        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.branchService = branchService;
        this.deliveryService = deliveryService;

        smoothscroll.polyfill();
    }

    ngAfterViewInit() {

    }

    swiperTouchStart(subNavSlider: SwiperComponent, $event: any) {
        const swiper = subNavSlider.directiveRef.swiper();
        swiper.setTranslate(swiper.getTranslate());
    }

    swiperTouchMove(evt) {
        this.menuNgSlider.directiveRef.swiper().allowTouchMove = false;
    }

    swiperTouchEnd(evt) {
        this.menuNgSlider.directiveRef.swiper().allowTouchMove = true;

        // const slider = evt[0];

        // setTimeout(() => {
        //     this.customSelectedCatAnimation(slider, slider.width, slider.slides, this.selectedSubCategory);
        // }, 10);
    }

    checkBirthDay() {
        if (this.catalogService.helpTexts.birthday && !this.appService.birthdayIsShowed) {
            this.appService.birthdayIsShowed = true;

            const s = localStorage.getItem('com.ionicframework.hatimaki__btd');
            let t = 0;
            if (s) {
                t = JSON.parse(s);
            }
            const n = (new Date()).getTime();

            if (!t || (n - t) / 1000 / 60 / 60 > 6) {
                this.appService.openModal(PopupBirthdayComponent, {birthday: this.catalogService.helpTexts.birthday});
                localStorage.setItem('com.ionicframework.hatimaki__btd', JSON.stringify((new Date()).getTime()));
            }
        }
    }

    ngOnInit() {

        this.subscriptions.push(this.appService.resetMenuSwiper$.subscribe(() => {
            this.menuNgSlider.directiveRef.setIndex(0);
            const wrapperElem = document.querySelector(`.wrapper-0`) as HTMLElement;
            wrapperElem.scroll(0, 0);
        }));

        this.phone = this.catalogService.phone;
    }

    ionViewWillEnter() {
        this.changeMenu$ = this.appService.changeMenu$.subscribe(() => {
            if (!this.isMenuLoading) {
                this.isMenuLoading = true;
                this.appService.showLoading();
            }

            this.appService.menuReady = false;
            this.appService.blockSwipeBack = false;

            this.navSliderInit = false;
            this.subNavSliderInit = false;
            this.menuNgSliderInit = false;
            this.blogSliderInit = false;

            this.selectedCat = 0;
            this.selectedCatForSub = 0;
            this.selectedCatHaveSubCategoryFlag = 0;
            this.subCategoryOffsets = [];
            this.selectedSubCategory = 0;
            this.showSubMenu = false;
            this.selectedSubCatElem = null;
            this.slides = [];

            this.scrollTimer = null;
            this.scrollInAction = false;
            this.scrollMenuTimer = null;
            this.scrollMenuInAction = false;
            this.menuTypeTimer = null;
            this.menuTypeTimerReady = true;
            this.timer = null;
            this.isClickedNav = false;


            this.blogSliderActiveIndex = 0;

            setTimeout(() => {
                this.appService.menuReady = true;
                this.cdr.detectChanges();
            });
        });
        if (this.appService.isMenuChanged) {
            this.appService.changeMenu();
        }
        // временно установлен по умолчанию вид меню list, т. к. при другом отображении меню по пока не
        // выясненной причине при перелистывании слайдов периодически перекидывает на главную страницу
        this.appService.menuType = 'list';
        localStorage.setItem('com.ionicframework.hatimaki__menuType', this.appService.menuType);
        if (this.appService.menuType === 'list') {
            AnalyticsService.pageVisit('Меню Список');
        } else {
            AnalyticsService.pageVisit('Меню');
        }
        // после удаления и перехода в меню открывается попап "выберите адрес", если defaultAddress пустой
        if (!this.userService.defaultAddress && this.catalogService.mainProductArray.length) {
            this.changeDefaultAddress();
        }

        this.appService.hideFooterMenu = false;
        this.checkBirthDay();
    }

    ionViewDidLeave() {
        if (this.changeMenu$) {
            this.changeMenu$.unsubscribe();
            this.changeMenu$ = null;
        }
    }

    menuReady() {
        this.afterMenuTypeChange();
        this.userService.getDeposit();
        this.checkBirthDay();
        this.showModalWorkTime();
        this.appService.isMenuChanged = false;

        // Показываем меню, после окончания рендера (после смены defaultAddress)
        this.appService.showMenuObject.opacity = 1;
        if (this.isMenuLoading) {
            this.isMenuLoading = false;
            this.appService.hideLoading();
        }
        // Закрываем попап выбора адреса, если он открыт
        this.appService.closeDefaultAddressModal();
        this.cdr.detectChanges();
        this.appService.firebasex.stopTrace(FirebaseTraces.menuReady);
        setTimeout(() => {
            this.appService.hideSplash();
        }, 300);
    }

    showModalWorkTime() {
        const now = new Date();
        let h = (now.getUTCHours() + 3).toString(); // получение времени с учетом таймзоны москвы
        let m = now.getMinutes().toString();
        const timeStart = this.catalogService.settings.workingTime.start;
        const timeEnd = this.catalogService.settings.workingTime.end;
        if (h.length < 2) {
            h = '0' + h;
        }
        if (m.length < 2) {
            m = '0' + m;
        }

        const nn = h + ':' + m;

        if ((nn < timeStart || nn > timeEnd) && !this.appService.workTimeIsShowed) {
            this.appService.workTimeIsShowed = true;
            this.appService.openModal(PopupWorkTimeComponent, {value: this.catalogService.helpTexts.workingTimePopup}, (data) => {

            });
        }
    }

    checkForReady() {
        if (this.navSliderInit &&
            this.menuNgSliderInit &&
            this.blogSliderInit &&
            this.subNavSliderInit &&
            this.appService.menuReady) {

            this.menuReady();
        }
    }

    navSliderReady(navSlider: SwiperComponent) {
        this.navSliderInit = true;
        this.navSlider.config = this.navSliderOptions;
        this.checkForReady();
    }

    subNavSliderReady(subNavSlider: SwiperComponent, index: number) {
        this.subNavSliderInit = true;
        this.slides[index] = subNavSlider;
        this.subNavSlider.config = this.subNavSliderOptions;
        if (this.slides[this.selectedCat]) {
            this.slides[this.selectedCat].directiveRef.swiper().slideTo(this.selectedSubCategory);
        }
        this.checkForReady();
        this.slides.forEach((el, i) => {
            const slider = this.slides[i].directiveRef.swiper();

            if (slider) {
                this.customSelectedCatAnimation(slider, slider.width, slider.slides, 0);
            }

        });
    }

    menuNgSliderReady(menuNgSlider: SwiperComponent) {
        this.menuNgSliderInit = true;
        this.appService.getWindow().testSwiper = menuNgSlider;
        this.checkForReady();
    }

    blogSliderReady(blogSlider: SwiperComponent) {
        this.blogSliderInit = true;
        this.checkForReady();
    }

    menuNgSlideChange(index: number) {
        if (!this.isClickedNav) {
            this.setNavSlider(index, false);
        }
    }

    menuSwiperTouchMove() {
        if (this.scrollMenuTimer) {
            clearTimeout(this.scrollMenuTimer);
        }

        this.scrollMenuInAction = false;
        this.isClickedNav = false;
    }

    onClickNavSlider(index: number) {
        this.isClickedNav = true;
        this.setNavSlider(index);
    }

    setNavSlider(index: number, changeMenu = true) {
        this.selectedCat = index;

        this.selectedCatHaveSubCategoryFlag = this.selectedCatHaveSubCategory();

        if (changeMenu && this.menuNgSlider) {
            this.menuNgSlider.directiveRef.setIndex(index);

            // if (this.scrollMenuTimer) {
            //     clearTimeout(this.scrollMenuTimer);
            // }

            this.scrollMenuInAction = true;
            // this.scrollMenuTimer = setTimeout(() => {
            //     this.scrollMenuInAction = false;
            //     this.isClickedNav = false;
            // }, this.scrollMenuTime * 2 );
        }
        this.navSlider.directiveRef.swiper().slideTo(index);
        this.getSelectedSubCategory(index);
    }

    scrollToCat(subCatId: number, index: number, evt: any) {
        evt.stopPropagation();

        const wrapperElem = document.querySelector(`.wrapper-${this.selectedCat}`) as HTMLElement;
        this.selectedSubCatElem = wrapperElem.querySelector(`.product-sub-category-${subCatId}`) as HTMLElement;

        wrapperElem.scrollTo({
            top: this.selectedSubCatElem.offsetTop - 43,
            left: 0,
            behavior: 'smooth'
        });

        this.selectedSubCategory = index;
        if (this.slides[this.selectedCat]) {
            const slider = this.slides[this.selectedCat].directiveRef.swiper();
            this.slides[this.selectedCat].directiveRef.swiper().slideTo(this.selectedSubCategory);
            this.customSelectedCatAnimation(slider, slider.width, slider.slides, index);
        }

        this.selectedSubCategoryIndex[this.selectedCat] = this.selectedSubCategory;

        if (this.scrollTimer) {
            clearTimeout(this.scrollTimer);
        }

        this.scrollInAction = true;
    }

    getSelectedSubCategory(productCatIndex: number) {
        if (this.selectedSubCategoryIndex[productCatIndex] !== undefined) {
            this.selectedSubCategory = this.selectedSubCategoryIndex[productCatIndex];
        } else {
            this.selectedSubCategory = 0;
        }
        if (this.slides[this.selectedCat]) {
            this.slides[this.selectedCat].directiveRef.swiper().slideTo(this.selectedSubCategory);
        }

        this.cdr.detectChanges();
    }

    selectedCatHaveSubCategory() {
        return this.catalogService.categories[this.selectedCat] &&
            this.catalogService.categories[this.selectedCat].subCategories &&
            this.catalogService.categories[this.selectedCat].subCategories.length;
    }

    onPageScroll(evt: any) {
        evt.stopPropagation();
        const scrollElem = evt.target as HTMLElement;
        // проверяем, что скролл страницы не более 0, только в этом случае должен работать refresh
        this.pageInTop = scrollElem.scrollTop === 0;

        // проверка остановки скролла при клике по меню подкатегорий
        if (this.selectedSubCatElem !== null && this.scrollInAction) {

            const scrollElemTop = scrollElem.scrollTop;
            const scrollElemBottom = scrollElem.scrollTop + scrollElem.clientHeight;

            if ((this.selectedSubCatElem.offsetTop === scrollElemTop + 43) ||
                (this.selectedSubCatElem.offsetTop < scrollElemBottom &&
                    this.selectedSubCatElem.offsetTop > scrollElemTop + 43)) {   // values: 43 - height header, 70 - height empty div

                setTimeout(() => {
                    this.scrollInAction = false;
                }, 300);

                this.selectedSubCatElem = null;
            }
            return;
        }

        if (this.selectedCatHaveSubCategoryFlag && !this.scrollInAction) {
            const subCategories = this.catalogService.categories[this.selectedCat].subCategories;
            let activeIndex = 0;
            for (let i = 0; i < subCategories.length; i++) {

                const wrapperElem = document.querySelector(`.wrapper-${this.selectedCat}`) as HTMLElement;
                const subCatElem = wrapperElem.querySelector(`.product-sub-category-${subCategories[i].id}`) as HTMLElement;

                const val = subCatElem.offsetTop;

                if (val - scrollElem.scrollTop <= 43) {
                    activeIndex = i;
                }

                if ((scrollElem.scrollHeight - scrollElem.scrollTop === scrollElem.clientHeight) &&
                    (subCatElem.offsetTop + subCatElem.clientHeight - 43 + 70 === scrollElem.scrollHeight)) {
                    // values: 43 - height header, 70 - height empty div
                    activeIndex = i;
                }
            }

            if (activeIndex !== this.selectedSubCategory) {
                this.selectedSubCategory = activeIndex;
                if (this.slides[this.selectedCat]) {

                    const slider = this.slides[this.selectedCat].directiveRef.swiper();

                    slider.slideTo(this.selectedSubCategory);
                    this.customSelectedCatAnimation(slider, slider.width, slider.slides, activeIndex);
                }
                this.selectedSubCategoryIndex[this.selectedCat] = this.selectedSubCategory;
            }
        }
    }

    swiperAnimated(e) {
        let slider = this.slides[e.activeIndex];

        if (slider) {
            slider = slider.directiveRef.swiper();

            setTimeout(() => {
                this.customSelectedCatAnimation(slider, slider.width, slider.slides, this.selectedSubCategory);
            }, 50);

        }
    }

    customSelectedCatAnimation(slider, containerWidth, slides, activeIndex = 0) {
        let activeSlideOffsetLeft = 0;
        let trf: number;

        slides.forEach((el, i) => {
            if (i <= activeIndex) {
                if (i === activeIndex) {
                    activeSlideOffsetLeft += el.offsetWidth / 2;
                } else {
                    activeSlideOffsetLeft += el.offsetWidth;
                }
            } else {
                return false;
            }
        });


        trf = containerWidth / 2 - activeSlideOffsetLeft;
        slider.translateTo(trf, 200, false, false);
    }

    navSliderChange() {
        this.showSubMenu = !!this.selectedCatHaveSubCategoryFlag;
    }

    toggleMenuType() {
        this.appService.menuType = this.appService.menuType === 'list' ? 'grid' : 'list';
        localStorage.setItem('com.ionicframework.hatimaki__menuType', this.appService.menuType);

        for (const prop in this.selectedSubCategoryIndex) {
            if (this.selectedSubCategoryIndex.hasOwnProperty(prop)) {
                delete this.selectedSubCategoryIndex[prop];
            }
        }

        this.menuTypeTimerReady = false;
        if (this.menuTypeTimer) {
            clearTimeout(this.menuTypeTimer);
        }

        if (this.menuNgSlider) {
            this.menuNgSlider.directiveRef.setIndex(0, 0);
            this.setNavSlider(0);
            this.lockNavSwiper(this.appService.menuType === 'list');
        }

        setTimeout(() => {
            this.menuTypeTimerReady = true;
            this.afterMenuTypeChange();
        }, 600);
    }

    afterMenuTypeChange() {
        if (this.menuNgSlider) {
            this.lockNavSwiper(this.appService.menuType === 'list');
            this.menuNgSlider.directiveRef.update();
        }
    }

    getLazyDefaultImage(id) {
        return HelperService.getLazyDefaultImage(id);
    }

    lockNavSwiper(lock) {
        this.swiperLock = lock;
        this.menuNgSlider.directiveRef.swiper().allowSlideNext = !lock;
        this.menuNgSlider.directiveRef.swiper().allowSlidePrev = !lock;
        this.menuNgSlider.directiveRef.swiper().allowTouchMove = !lock;
    }

    onClickBlogSlider() {
        this.openPromoModal();
    }

    openPromoModal() {
        const activePromoSlide = this.catalogService.promos[this.blogSliderActiveIndex];
        this.modalService.createModal(PromoModalComponent, {promoId: activePromoSlide.id});
    }

    openBannerModal(banner) {
        this.modalService.createModal(BannerModalComponent, {banner});
    }

    onSearch() {
        this.navigationService.goToPage('search-page');
    }

    ngOnDestroy() {

        for (const prop in this.selectedSubCategoryIndex) {
            if (this.selectedSubCategoryIndex.hasOwnProperty(prop)) {
                delete this.selectedSubCategoryIndex[prop];
            }
        }

        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions.length = 0;
    }

    getBackgroundImageCss(imageUrl: string) {
        return this.sanitization.bypassSecurityTrustStyle(`url(${imageUrl})`);
    }

    isCategoryHavePromoBanner(productCat) {
        return productCat.promoBanner?.promotionText?.length;
    }

    onClickPromoBanner() {
        const category = this.catalogService.categories[this.selectedCat];
        if (category.promoBanner.promotionId) {
            this.modalService.createModal(PromoModalComponent, {promoId: category.promoBanner.promotionId});
        } else if (category.promoBanner.promotionLink) {
            this.appService.openLink(category.promoBanner.promotionLink);
        }
    }

    addressForHeader() {
        let text = '';
        if (!this.userService.defaultAddress) {
            return null;
        }
        switch (this.userService.defaultAddress.type) {
            case DeliveryType.Pickup:
                text = this.userService.defaultAddress.pickup?.street;
                break;
            case DeliveryType.Courier:
                text = this.userService.defaultAddress.courier?.street ?
                    this.userService.defaultAddress.courier.street :
                    '';
                break;
        }

        const div = document.createElement('div');
        div.innerHTML = text;
        text = div.textContent || div.innerText || '';
        return (text);
    }

    changeDefaultAddress() {
        // Открываем попап для выбора адреса
        this.appService.openModal(PopupSelectAddressComponent, {afterCatalog: false}, () => {
        }, false);
    }

    getDynamicRenderer(container: HTMLDivElement) {
        const productHeight = 180;
        const currentScroll = container.scrollTop;
        const screenHeight = document.querySelector('body').offsetHeight;
        const offsetElem: HTMLDivElement = container.querySelector('.main-menu-slide-flipper-wrapper');
        let offset = 0;
        if (offsetElem) {
            offset = offsetElem.offsetTop;
        }

        const countVisibleBlock = Math.ceil(screenHeight / productHeight) * 2.5; // прогружаем по 2.5 экрана по сторонам сверху и снизу

        const currentIndex = Math.floor((currentScroll - offset) / productHeight);
        return {
            index: 1,
            start: currentIndex - countVisibleBlock,
            end: currentIndex + countVisibleBlock
        };
    }

    async onRefresh(event) {
        // запрос на получение всех филиалов, чтобы обновить данные по актуальными статусам
        await this.branchService.getData(res => {
            // проверка наличия элемента с active=false и что его id совпадает с выбранным ранее филиалом
            const inactiveBranch = this.branchService.data.find(
                el => !el.active && el.id === this.userService.defaultAddress.pickup.id
            );
            this.catalogService.settings.pickupDiscount = this.branchService.data[0].pickupDiscount;

            // выбранный ранее филиал имеет статус active === false;
            if (inactiveBranch) {
                this.appService.openModal(PopupSelectAddressComponent, {
                    afterCatalog: true,
                    hideSplashWhenReady: true,
                    mandatoryAddressChange: true,
                    inactiveBranchId: this.userService.defaultAddress.pickup.id
                }, () => {
                }, false);
            } else {
                // находим нужный филиал, чтобы просто обновить время самовывоза и т. д.
                const pickup = this.branchService.data.find(
                    el => el.id === this.userService.defaultAddress.pickup.id
                );

                // если такой филиал существует
                if (pickup) {
                    // обновляем defaultAddress.pickup
                    this.userService.defaultAddress.pickup = pickup;
                }
            }
        }, false, true);
        await this.updateAddressData(this.userService.defaultAddress);
        await event.target.complete();
    }

    updateAddressData(defaultAddress: DefaultAddress) {
        const data = {
            term: defaultAddress.courier?.value,
            kladrId: defaultAddress.courier.kladrId,
            x: defaultAddress.courier.x,
            y: defaultAddress.courier.y,
            notLoading: true
        };
        // запрашиваем новые данные, передавая параметр data
        this.deliveryService.checkAddress(
            data,
            1,
            (status, address: AddressSearchAnswer) => {

                // заменяем defaultAddress courierPoint на новый
                this.userService.defaultAddress.courierPoint = address;

                // сохраняем обновлённый defaultAddress
                this.userService.saveDefaultAddress(this.userService.defaultAddress, () => {
                    // загрузка каталога не требуется
                }, {loadCatalog: false, loading: false});
            });
    }
}
