import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@awesome-cordova-plugins/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {NetworkInterface} from '@ionic-native/network-interface/ngx';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';

import {BottomMenuItemModule} from './components/bottom-menu/item.module';
import {ProductModalModule} from './components/product-modal/product-modal.module';
import {PromoModalModule} from './components/promo-modal/promo-modal.module';
import {SearchFilterModalModule} from './components/search-filter-modal/search-filter-modal.module';
import {NgxMaskModule} from 'ngx-mask';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {TextareaAutosizeModule} from 'ngx-textarea-autosize';
import {MainInterceptor} from './core/interceptors/main.interceptor';
import {WokModalModule} from './components/wok-constructor-modal/wok-modal.module';
import {LazyService} from './services/lazy.service';
import {BannerModalModule} from './components/banner-modal/banner-modal.module';
import {PizzaModalModule} from './components/pizza-constructor-modal/pizza-modal.module';
import {Camera} from '@ionic-native/camera/ngx';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {GlobalErrorHandler} from './core/errors/global-error-handler';
import {HttpErrorInterceptor} from './core/errors/http-error.interceptor';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {Clipboard} from '@awesome-cordova-plugins/clipboard/ngx';
import {registerLocaleData} from '@angular/common';
import localeRu from '@angular/common/locales/ru';

// Регистрация русской локали
registerLocaleData(localeRu, 'ru');

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            mode: 'ios'
        }),
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        BottomMenuItemModule,
        ProductModalModule,
        PromoModalModule,
        SearchFilterModalModule,
        BannerModalModule,
        WokModalModule,
        NgxMaskModule.forRoot(),
        TextareaAutosizeModule,
        PizzaModalModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        InAppBrowser,
        NetworkInterface,
        SocialSharing,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MainInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'ru'
        },
        FirebaseX,
        AppVersion,
        LazyService,
        LazyService,
        Deeplinks,
        Clipboard,
        Camera
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
