import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AppService} from 'src/app/services/app.service';
import {UserService} from 'src/app/models/user.service';
import {NavParams} from '@ionic/angular';
import {DeliveryType, OrderStep} from 'src/app/entity/cart.entity';
import {Branch} from '../../../entity/restaurant.entity';
import {BranchService} from '../../../models/branch.service';
import {DefaultAddress} from '../../../entity/profile.entity';
import {AddressSearchAnswer} from '../../../entity/addressSearchAPIAnswer.entity';
import {SuggestionsService} from '../../../models/suggestions.service';
import {DeliveryService} from '../../../models/delivery.service';
import {Subscription} from 'rxjs';
import {CatalogService} from '../../../models/catalog.service';
import {PizzaService} from '../../../models/pizza.service';
import {WokService} from '../../../models/wok.service';
import {CartService} from '../../../models/cart.service';
import {AddressService} from '../../../models/address.service';
import {Address} from '../../../entity/address.entity';

@Component({
    selector: 'app-popup-select-address',
    templateUrl: 'popup-select-address.html',
    styleUrls: ['popup-select-address.scss']
})
export class PopupSelectAddressComponent implements OnInit, OnDestroy {
    catalogService: CatalogService;

    constructor(
        private appService: AppService,
        private suggestionsService: SuggestionsService,
        private deliveryService: DeliveryService,
        public branchService: BranchService,
        private userService: UserService,
        private cdr: ChangeDetectorRef,
        private params: NavParams,
        catalogService: CatalogService,
        private pizzaService: PizzaService,
        private wokService: WokService,
        private cartService: CartService,
        public addressService: AddressService,
    ) {
        this.catalogService = catalogService;
        this.isCallbackAfterCatalog = params.get('afterCatalog');
        this.hideSplashWhenReady = params.get('hideSplashWhenReady');
        this.closeAllowedByExternal = params.get('closeAllowedByExternal');
    }

    p_defaultAddress: DefaultAddress;

    get defaultAddress() {
        return this.p_defaultAddress;
    }

    set defaultAddress(value: DefaultAddress) {
        this.p_defaultAddress = value;
       // console.log('value', value);
    }

    private citySearchTimer;

    pageReady = true;
    isOpenAddressList = false;
    isOpenPickUpList = false;
    isOpenCityList = false;
    isOpenTemplateList = false;
    addressConfirm = false;
    isCheckedStreet = false;
    isCheckedAddress = false;
    DeliveryType = DeliveryType;
    touchTarget: any;
    addressSuggestions: any[] = [];
    isExistingAddress = false;

    cityList = ['Москва и МО', 'Рязань'];
    selectedCityIndex = 0;
    isStreetError = false;
    private closeSubscription: Subscription = null;

    hideSplashWhenReady = false;
    isCallbackAfterCatalog = true;
    closeAllowedByExternal = false;
    mandatoryAddressChange = false;
    inactiveBranchId: number | null = null;

    // listener for close select
    isClosible = false;
    isHasDefaultAddress = true;

    prepareForm() {
        // создаем локальную копию деф адреса
        const type = this.defaultAddress?.type;
        this.defaultAddress = new DefaultAddress();
        if (type !== null && type !== undefined) {
            this.defaultAddress.type = type;
        }
        if (this.userService.defaultAddress) {
            console.log('this error');
            this.defaultAddress.type = this.userService.defaultAddress.type;
            this.defaultAddress.courier = {...this.userService.defaultAddress.courier};
            this.defaultAddress.pickup = {...this.userService.defaultAddress.pickup};
            this.defaultAddress.courierPoint = {...this.userService.defaultAddress.courierPoint};
        }
        // открываем нужный таб
        this.changeTab(this.defaultAddress.type);

        this.isCheckedStreet = !!this.defaultAddress.courier?.street;

        if (this.defaultAddress.courier?.value) {
            this.checkAddress();
        } else {
            this.isCheckedAddress = false;
        }

        // получаем список филиалов
        this.branchService.getData(res => {
            if (res) {
                // если получили толькой 1 филиал и он не выбран еще, то выбераем его
                if (this.defaultAddress.pickup === null && this.branchService.data.length === 1) {
                    this.selectBranch(this.branchService.data[0]);
                }
                this.cdr.detectChanges();
            }
        }, true);
        this.addressService.getAddresses(res => {
            if (res) {
                this.cdr.detectChanges();
            }
        }, true, false);
    }

    ngOnInit() {
        const cityId = this.appService.getCityId();
        if (cityId) {
            this.selectedCityIndex = Number(cityId);
        }

        // Если вызывающий компонент позаботился об обработке закрытия модалки без указания деф.адреса:
        // разрешить закрытие модалки (отобразить крестик в окне).
        // Иначе: разрешить закрытие модалки только если для текущего города указан деф.адрес.
        this.isClosible = (
            !!this.closeAllowedByExternal ? this.closeAllowedByExternal : !!this.userService.defaultAddress)
            // если выбранный пользователем ранее филиал на момент запуска приложения стал не активен,
            // то закрыть окно выбора адреса пользователь уже не может, пока не выберет либо другой филиал,
            // либо изменит тип на самовывоз
            && !this.mandatoryAddressChange;
        this.isHasDefaultAddress = this.userService.isHasDefaultAddress;

        this.prepareForm();

        // Подписываемся на закрытие через appService
        this.closeSubscription = this.appService.eventCloseDefaultAddressModal.subscribe(() => {
            this.close();
        });
    }

    ionViewWillEnter() {
        // блокируем физ. кнопку назад
        if (!this.userService.defaultAddress) {
            this.appService.blockBack = true;
        }

        if (this.defaultAddress.courier?.street) {
            this.isCheckedStreet = true;
        }

        if (this.hideSplashWhenReady) {
            this.appService.hideSplash();
        }
    }

    close(action: string = 'close') {
        // блочим закрытие, если у пользователя нет деф. адреса
        // Если у пользователя нет деф.адреса, но вызывающий компонент разрешил - закрытие допустимо,
        // но только для действия 'close' (кнопка "Подтвердить" не сработает)
        if ((this.userService.defaultAddress || (this.closeAllowedByExternal && action === 'close')) &&
            // если выбранный пользователем ранее филиал на момент запуска приложения стал не активен,
            // то закрыть окно выбора адреса пользователь уже не может, пока не выберет либо другой филиал,
            // либо изменит тип на самовывоз
            (!this.mandatoryAddressChange || action === 'saved')) {
            this.appService.blockBack = false;
            this.appService.closeModal({action});
        }
    }

    trackByFn(index, item) {
        return item.id;
    }

    @HostListener('document:touchend', ['$event.target'])
    touchend(target) {
        let needUpdate = false;
        this.touchTarget = target;

        if ((this.isOpenAddressList || !this.defaultAddress.courier?.value) && !target.closest('.input_address')) {
            if (!this.isCheckedAddress) {
                this.clearAddress(true);
            }
            this.isOpenAddressList = false;
            needUpdate = true;
        }

        if (this.isOpenPickUpList && !target.closest('.input_branch_pickup')) {
            this.isOpenPickUpList = false;
            needUpdate = true;
        }
        if (this.isOpenTemplateList && !target.closest('.input_template')) {
            this.isOpenTemplateList = false;
            needUpdate = true;
        }

        if (this.isOpenCityList && !target.closest('.input_city')) {
            this.isOpenCityList = false;
        }

        if (needUpdate) {
            this.cdr.detectChanges();
        }

    }

    changeTab(index: DeliveryType) {
        this.defaultAddress.type = index;
    }

    getSuggestions() {
        this.isCheckedStreet = false;
        this.isCheckedAddress = false;
        if (this.defaultAddress.courier?.value?.length > 1) {
            clearTimeout(this.citySearchTimer);
            const streetDelta = this.defaultAddress.courier?.value;
            this.addressSuggestions = [];
            this.isOpenAddressList = false;

            this.citySearchTimer = setTimeout(() => {
                this.suggestionsService.getSuggestions(this.defaultAddress.courier?.value, (data: any) => {
                    if (streetDelta === this.defaultAddress.courier?.value) {
                        this.addressSuggestions = data ? data : [];
                        this.isOpenAddressList = !!this.addressSuggestions?.length;
                        // this.cdr.detectChanges();

                        // if something typed in input field (even after confirmed address was selected):
                        // this will reset address fields if (no suggestion selected && (input field lost focus || keydown.enter))
                    } else {
                        this.addressSuggestions = [];
                        this.isOpenAddressList = false;
                    }
                }, false);
                clearTimeout(this.citySearchTimer);
            }, 500);
        } else {
            this.addressSuggestions = [];
            this.isOpenAddressList = false;
        }
    }

    selectAddress(address, event?: any) {
        event?.stopPropagation();
        // console.log('address', address);
        this.defaultAddress.courier = new Address();
        // console.log('test', this.defaultAddress.courier);
        this.defaultAddress.courier.kladrId = address.id;
        this.defaultAddress.courier.block = address.block;
        this.defaultAddress.courier.house = address.house;
        this.defaultAddress.courier.street = address.street;
        this.defaultAddress.courier.value = address.value;
        this.defaultAddress.courier.x = address.x;
        this.defaultAddress.courier.y = address.y;
        // console.log('address courier', this.defaultAddress.courier);
        this.isOpenAddressList = false;
        this.isCheckedStreet = true;
    }

    checkAddress() {
        this.addressConfirm = false;
        if (
            this.defaultAddress.courier?.street &&
            this.defaultAddress.courier?.house
        ) {
            const data = {
                term: this.defaultAddress.courier?.value,
                kladrId: this.defaultAddress.courier.kladrId,
                x: this.defaultAddress.courier.x,
                y: this.defaultAddress.courier.y,
            };
            this.deliveryService.checkAddress(
                    data,
                1,
                (status, address: AddressSearchAnswer) => {
                    this.defaultAddress.courierPoint = address;
                    this.addressConfirm = status;
                    this.isCheckedAddress = true;
                    if (!this.addressConfirm) {
                        this.clearAddress();
                    }
                    this.checkTemplateExistence();
                });
        } else {
            this.clearAddress();
            this.addressConfirm = false;
        }
    }

    checkStreet(evt: Event) {
        const street = (evt.target as HTMLInputElement).value.trim();
        if (!street) {
            this.isCheckedStreet = false;
            this.clearAddress();
        }
        this.addressConfirm = false;
        setTimeout(() => {
            this.checkAddress();
        }, 10);

    }

    onEnter() {
        if (this.addressSuggestions?.length) {
            this.selectAddress(this.addressSuggestions[0]);
        } else {
            if (!this.isCheckedStreet) {
                this.clearAddress(true);
            }
        }
    }

    onDelete(event: any) {
        if (event.target.value.length === 0) {
            this.clearAddressUnavailable();
        }
    }

    onBlur() {
        if (!this.touchTarget.closest('.warp-list') && !this.isCheckedStreet) {
            this.clearAddress(true);
        }
    }

    clearAddress(all?: boolean) {
        if (all) {
            this.isExistingAddress = false;
            this.addressConfirm = false;
            this.isCheckedStreet = false;
            this.defaultAddress.courier.street = '';
            this.defaultAddress.courier.kladrId = '';
            this.defaultAddress.courier.value = '';
            this.defaultAddress.courier.x = '';
            this.defaultAddress.courier.y = '';
            this.clearAddressUnavailable();
        }
        this.defaultAddress.courier.porch = '';
        this.defaultAddress.courier.intercom = '';
        this.defaultAddress.courier.floor = '';
        this.defaultAddress.courier.office = '';
        this.addressSuggestions = [];
    }

    checkTemplateExistence() {
        const address = this.addressService.addresses.find(item => {
            return item.value === this.defaultAddress.courier.value;
        });
        if (address) {
            this.defaultAddress.courier = {...address};
        }
        this.isExistingAddress = !!address;
        this.cdr.detectChanges();
    }

    checkPickupOnlyText() {
        if (!this.addressConfirm && !this.defaultAddress.courier.house) {
            return 'Выберите адрес с домом';
        }
        if (!this.addressConfirm && this.defaultAddress.courierPoint.addressUnavailable) {
            return this.defaultAddress.courierPoint.addressUnavailable.underAddressText;
        }
    }

    selectBranch(branch: Branch) {
        this.isOpenPickUpList = false;
        this.defaultAddress.pickup = branch;
    }

    saveDefaultAddress() {
        this.userService.saveDefaultAddress(this.defaultAddress, (type) => {
            if (
                (type === 'address' && !this.isCallbackAfterCatalog) ||
                (type === 'catalog' && this.isCallbackAfterCatalog)
            ) {
                this.close('saved');
            }
        });
    }

    ngOnDestroy() {
        if (this.closeSubscription) {
            this.closeSubscription.unsubscribe();
            this.closeSubscription = null;
        }
        this.clearAddressUnavailable();
    }

    selectCity(city: number) {
        this.selectedCityIndex = city;
        this.isOpenCityList = false;

        if (this.appService.getCityId() === city.toString()) {
            return;
        }

        // выбрали другой город
        localStorage.setItem('com.ionicframework.hatimaki__city', city.toString());

        this.userService.user.city = city;
        this.userService.getDefaultAddress();
        this.appService.getCity();
        this.appService.menuReady = false;
        this.branchService.data.length = 0;
        this.catalogService.clearSelectedSideDish();
        this.pizzaService.clear();
        this.wokService.clear();

        this.userService.getUser(() => {
            this.prepareForm();
            this.cartService.sync(OrderStep.Start);
        });


    }

    clearAddressUnavailable() {
        // очистка addressUnavailable, если больше не требуется
        // с проверкой на существование courierPoint
        if (this.defaultAddress.courierPoint) {
            this.defaultAddress.courierPoint.addressUnavailable = {
                underAddressText: '',
                title: '',
                text: ''
            };
        }
    }

    selectAddressTemplate(address: Address) {
        for (const val in address) {
            if (typeof address[val] === 'string') {
                address[val] = address[val].trim();
            }
        }

        if (address && address.id) {
            this.defaultAddress.courier = {...address};
            this.isCheckedAddress = true;
            this.isCheckedStreet = true;
            this.addressConfirm = true;
            this.isOpenTemplateList = false;
            this.isExistingAddress = true;
            this.addressSuggestions = [];
            this.checkAddress();
        }
    }
}
